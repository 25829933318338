export default function (api) {
  return {
    login(data, headers) {
      return api.post('/login', data, { headers, shouldAuth: false })
    },
    upload(data) {
      return api.post('/upload', data, { shouldAuth: true })
    },
    uploadPreview(data) {
      return api.post('/upload/preview', data, { shouldAuth: true })
    },
    createMerchant(data) {
      return api.post('/merchant', data, { shouldAuth: true })
    },
    updateMerchant(id, data) {
      return api.put('/merchant/' + id, data, { shouldAuth: true })
    },
    deleteMerchant(id) {
      return api.delete('/merchant/' + id, { shouldAuth: true })
    },
    getMerchantList(data) {
      return api.get('/merchant', { params: data, shouldAuth: true })
    },
    getMerchantDetail(id) {
      return api.get('/merchant/detail/' + id, { shouldAuth: true })
    },
    downloadDocument(merchant_id, document_id) {
      return api.get(`/merchant/${merchant_id}/document/${document_id}/download`, {
        responseType: 'blob',
        shouldAuth: true
      })
    },
    // config
    getOpenaiConfig() {
      return api.get('/config/openai', { shouldAuth: true })
    },
    updateOpenaiConfig(data) {
      return api.put('/config/openai', data, { shouldAuth: true })
    },
    // chat history
    getChatHistory(merchant_id, data) {
      return api.get(`/merchant/${merchant_id}/chatHistory`, { params: data, shouldAuth: true })
    },
    downloadChatHistory(merchant_id, data) {
      return api.get(`/merchant/${merchant_id}/chatHistory/csv`, {
        params: data,
        responseType: 'blob',
        shouldAuth: true
      })
    },
    // welcome list
    getWelcomeList(merchant_id, data) {
      return api.get(`/merchant/${merchant_id}/welcome`, { params: data, shouldAuth: true })
    },
    createWelcome(merchant_id, data) {
      return api.post(`/merchant/${merchant_id}/welcome`, data, { shouldAuth: true })
    },
    updateWelcome(merchant_id, id, data) {
      return api.put(`/merchant/${merchant_id}/welcome/${id}`, data, { shouldAuth: true })
    },
    deleteWelcome(merchant_id, id) {
      return api.delete(`/merchant/${merchant_id}/welcome/${id}`, { shouldAuth: true })
    },

    // common question list
    getCommonQuestionList(merchant_id, data) {
      return api.get(`/merchant/${merchant_id}/commonQuestion`, { params: data, shouldAuth: true })
    },
    createCommonQuestion(merchant_id, data) {
      return api.post(`/merchant/${merchant_id}/commonQuestion`, data, { shouldAuth: true })
    },
    updateCommonQuestion(merchant_id, id, data) {
      return api.put(`/merchant/${merchant_id}/commonQuestion/${id}`, data, { shouldAuth: true })
    },
    deleteCommonQuestion(merchant_id, id) {
      return api.delete(`/merchant/${merchant_id}/commonQuestion/${id}`, { shouldAuth: true })
    }
  }
}
